/*
 * Table styles
 */
 table.dataTable {
    width: 100%;
    margin: 0 auto;
    padding-top : 10px;
    clear: both;
    border-collapse: separate;
    border-spacing: 0;
    /*
     * Header and footer styles
     */
    /*
     * Body styles
     */
  }
  table.dataTable thead th,
  table.dataTable tfoot th {
    font-weight: bold;
  }
  table.dataTable thead th,
  table.dataTable thead td {
    padding: 10px 18px;
    border-bottom: 1px solid #111111;
  }
  table.dataTable thead th:active,
  table.dataTable thead td:active {
    outline: none;
  }
  table.dataTable tfoot th,
  table.dataTable tfoot td {
    padding: 10px 18px 6px 18px;
    border-top: 1px solid #111111;
  }
  table.dataTable thead .sorting_asc,
  table.dataTable thead .sorting_desc,
  table.dataTable thead .sorting {
    cursor: pointer;
    *cursor: hand;
  }
  table.dataTable thead .sorting {
    background: url("../../resources/images/sort_both.png") no-repeat center right;
  }
  table.dataTable thead .sorting_asc {
    background: url("../../resources/images/sort_asc.png") no-repeat center right;
  }
  table.dataTable thead .sorting_desc {
    background: url("../../resources/images/sort_desc.png") no-repeat center right;
  }
  table.dataTable thead .sorting_asc_disabled {
    background: url("../../resources/images/sort_asc_disabled.png") no-repeat center right;
  }
  table.dataTable thead .sorting_desc_disabled {
    background: url("../../resources/images/sort_desc_disabled.png") no-repeat center right;
  }
  table.dataTable tbody tr {
    background-color: white;
  }
  table.dataTable tbody tr.selected {
    background-color: #b0bed9;
  }
  table.dataTable tbody th,
  table.dataTable tbody td {
    padding: 8px 10px;
  }
  table.dataTable th.center,
  table.dataTable td.center,
  table.dataTable td.dataTables_empty {
    text-align: center;
  }
  table.dataTable th.right,
  table.dataTable td.right {
    text-align: right;
  }
  table.dataTable.row-border tbody th, table.dataTable.row-border tbody td, table.dataTable.display tbody th, table.dataTable.display tbody td {
    border-top: 1px solid #dddddd;
  }
  table.dataTable.row-border tbody tr:first-child th,
  table.dataTable.row-border tbody tr:first-child td, table.dataTable.display tbody tr:first-child th,
  table.dataTable.display tbody tr:first-child td {
    border-top: none;
  }
  table.dataTable.cell-border tbody th, table.dataTable.cell-border tbody td {
    border-top: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
  }
  table.dataTable.cell-border tbody tr th:first-child,
  table.dataTable.cell-border tbody tr td:first-child {
    border-left: 1px solid #dddddd;
  }
  table.dataTable.cell-border tbody tr:first-child th,
  table.dataTable.cell-border tbody tr:first-child td {
    border-top: none;
  }
  table.dataTable.stripe tbody tr.odd, table.dataTable.display tbody tr.odd {
    background-color: #f9f9f9;
  }
  table.dataTable.stripe tbody tr.odd.selected, table.dataTable.display tbody tr.odd.selected {
    background-color: #abb9d3;
  }
  table.dataTable.hover tbody tr:hover,
  table.dataTable.hover tbody tr.odd:hover,
  table.dataTable.hover tbody tr.even:hover, table.dataTable.display tbody tr:hover,
  table.dataTable.display tbody tr.odd:hover,
  table.dataTable.display tbody tr.even:hover {
    background-color: whitesmoke;
  }
  table.dataTable.hover tbody tr:hover.selected,
  table.dataTable.hover tbody tr.odd:hover.selected,
  table.dataTable.hover tbody tr.even:hover.selected, table.dataTable.display tbody tr:hover.selected,
  table.dataTable.display tbody tr.odd:hover.selected,
  table.dataTable.display tbody tr.even:hover.selected {
    background-color: #a9b7d1;
  }
  table.dataTable.order-column tbody tr > .sorting_1,
  table.dataTable.order-column tbody tr > .sorting_2,
  table.dataTable.order-column tbody tr > .sorting_3, table.dataTable.display tbody tr > .sorting_1,
  table.dataTable.display tbody tr > .sorting_2,
  table.dataTable.display tbody tr > .sorting_3 {
    background-color: #f9f9f9;
  }
  table.dataTable.order-column tbody tr.selected > .sorting_1,
  table.dataTable.order-column tbody tr.selected > .sorting_2,
  table.dataTable.order-column tbody tr.selected > .sorting_3, table.dataTable.display tbody tr.selected > .sorting_1,
  table.dataTable.display tbody tr.selected > .sorting_2,
  table.dataTable.display tbody tr.selected > .sorting_3 {
    background-color: #acbad4;
  }
  table.dataTable.display tbody tr.odd > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
    background-color: #f1f1f1;
  }
  table.dataTable.display tbody tr.odd > .sorting_2, table.dataTable.order-column.stripe tbody tr.odd > .sorting_2 {
    background-color: #f3f3f3;
  }
  table.dataTable.display tbody tr.odd > .sorting_3, table.dataTable.order-column.stripe tbody tr.odd > .sorting_3 {
    background-color: whitesmoke;
  }
  table.dataTable.display tbody tr.odd.selected > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_1 {
    background-color: #a6b3cd;
  }
  table.dataTable.display tbody tr.odd.selected > .sorting_2, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_2 {
    background-color: #a7b5ce;
  }
  table.dataTable.display tbody tr.odd.selected > .sorting_3, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_3 {
    background-color: #a9b6d0;
  }
  table.dataTable.display tbody tr.even > .sorting_1, table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
    background-color: #f9f9f9;
  }
  table.dataTable.display tbody tr.even > .sorting_2, table.dataTable.order-column.stripe tbody tr.even > .sorting_2 {
    background-color: #fbfbfb;
  }
  table.dataTable.display tbody tr.even > .sorting_3, table.dataTable.order-column.stripe tbody tr.even > .sorting_3 {
    background-color: #fdfdfd;
  }
  table.dataTable.display tbody tr.even.selected > .sorting_1, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_1 {
    background-color: #acbad4;
  }
  table.dataTable.display tbody tr.even.selected > .sorting_2, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_2 {
    background-color: #adbbd6;
  }
  table.dataTable.display tbody tr.even.selected > .sorting_3, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_3 {
    background-color: #afbdd8;
  }
  table.dataTable.display tbody tr:hover > .sorting_1,
  table.dataTable.display tbody tr.odd:hover > .sorting_1,
  table.dataTable.display tbody tr.even:hover > .sorting_1, table.dataTable.order-column.hover tbody tr:hover > .sorting_1,
  table.dataTable.order-column.hover tbody tr.odd:hover > .sorting_1,
  table.dataTable.order-column.hover tbody tr.even:hover > .sorting_1 {
    background-color: #eaeaea;
  }
  table.dataTable.display tbody tr:hover > .sorting_2,
  table.dataTable.display tbody tr.odd:hover > .sorting_2,
  table.dataTable.display tbody tr.even:hover > .sorting_2, table.dataTable.order-column.hover tbody tr:hover > .sorting_2,
  table.dataTable.order-column.hover tbody tr.odd:hover > .sorting_2,
  table.dataTable.order-column.hover tbody tr.even:hover > .sorting_2 {
    background-color: #ebebeb;
  }
  table.dataTable.display tbody tr:hover > .sorting_3,
  table.dataTable.display tbody tr.odd:hover > .sorting_3,
  table.dataTable.display tbody tr.even:hover > .sorting_3, table.dataTable.order-column.hover tbody tr:hover > .sorting_3,
  table.dataTable.order-column.hover tbody tr.odd:hover > .sorting_3,
  table.dataTable.order-column.hover tbody tr.even:hover > .sorting_3 {
    background-color: #eeeeee;
  }
  table.dataTable.display tbody tr:hover.selected > .sorting_1,
  table.dataTable.display tbody tr.odd:hover.selected > .sorting_1,
  table.dataTable.display tbody tr.even:hover.selected > .sorting_1, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1,
  table.dataTable.order-column.hover tbody tr.odd:hover.selected > .sorting_1,
  table.dataTable.order-column.hover tbody tr.even:hover.selected > .sorting_1 {
    background-color: #a1aec7;
  }
  table.dataTable.display tbody tr:hover.selected > .sorting_2,
  table.dataTable.display tbody tr.odd:hover.selected > .sorting_2,
  table.dataTable.display tbody tr.even:hover.selected > .sorting_2, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2,
  table.dataTable.order-column.hover tbody tr.odd:hover.selected > .sorting_2,
  table.dataTable.order-column.hover tbody tr.even:hover.selected > .sorting_2 {
    background-color: #a2afc8;
  }
  table.dataTable.display tbody tr:hover.selected > .sorting_3,
  table.dataTable.display tbody tr.odd:hover.selected > .sorting_3,
  table.dataTable.display tbody tr.even:hover.selected > .sorting_3, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3,
  table.dataTable.order-column.hover tbody tr.odd:hover.selected > .sorting_3,
  table.dataTable.order-column.hover tbody tr.even:hover.selected > .sorting_3 {
    background-color: #a4b2cb;
  }
  table.dataTable.no-footer {
    border-bottom: 1px solid #111111;
  }
  
  table.dataTable,
  table.dataTable th,
  table.dataTable td {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  
  td.details-control {
  /*     background: url('../images/details_open.png') no-repeat center center; */
      cursor: pointer;
  }
  tr.shown td.details-control {
  /*     background: url('../images/details_close.png') no-repeat center center; */
  }
  
  td.map-control {
  /*     background: url('../images/details_open.png') no-repeat center center; */
      cursor: pointer;
  }
  
  /*
   * Control feature layout
   */
  .dataTables_wrapper {
    position: relative;
    clear: both;
    *zoom: 1;
    zoom: 1;
  }
  .dataTables_wrapper .dataTables_length {
    float: left;
  }
  .dataTables_wrapper .dataTables_filter {
    float: right;
    text-align: right;
  }
  .dataTables_wrapper .dataTables_filter input {
    margin-left: 0.5em;
  }
  .dataTables_wrapper .dataTables_info {
    clear: both;
    float: left;
    padding-top: 0.755em;
  }
  .dataTables_wrapper .dataTables_paginate {
    float: right;
    text-align: right;
    padding-top: 0.25em;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button {
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 0.5em 1em;
    margin-left: 2px;
    text-align: center;
    text-decoration: none !important;
    cursor: pointer;
    *cursor: hand;
    color: #333333 !important;
    border: 1px solid transparent;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: #333333 !important;
    border: 1px solid #cacaca;
    background-color: white;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, white), color-stop(100%, gainsboro));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, white 0%, gainsboro 100%);
    /* Chrome10+,Safari5.1+ */
    background: -moz-linear-gradient(top, white 0%, gainsboro 100%);
    /* FF3.6+ */
    background: -ms-linear-gradient(top, white 0%, gainsboro 100%);
    /* IE10+ */
    background: -o-linear-gradient(top, white 0%, gainsboro 100%);
    /* Opera 11.10+ */
    background: linear-gradient(to bottom, white 0%, gainsboro 100%);
    /* W3C */
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
    cursor: default;
    color: #666 !important;
    border: 1px solid transparent;
    background: transparent;
    box-shadow: none;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    color: white !important;
    border: 1px solid #111111;
    background-color: #585858;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #585858), color-stop(100%, #111111));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #585858 0%, #111111 100%);
    /* Chrome10+,Safari5.1+ */
    background: -moz-linear-gradient(top, #585858 0%, #111111 100%);
    /* FF3.6+ */
    background: -ms-linear-gradient(top, #585858 0%, #111111 100%);
    /* IE10+ */
    background: -o-linear-gradient(top, #585858 0%, #111111 100%);
    /* Opera 11.10+ */
    background: linear-gradient(to bottom, #585858 0%, #111111 100%);
    /* W3C */
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button:active {
    outline: none;
    background-color: #2b2b2b;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #2b2b2b), color-stop(100%, #0c0c0c));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
    /* Chrome10+,Safari5.1+ */
    background: -moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
    /* FF3.6+ */
    background: -ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
    /* IE10+ */
    background: -o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
    /* Opera 11.10+ */
    background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
    /* W3C */
    box-shadow: inset 0 0 3px #111;
  }
  .dataTables_wrapper .dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 40px;
    margin-left: -50%;
    margin-top: -25px;
    padding-top: 20px;
    text-align: center;
    font-size: 1.2em;
    background-color: white;
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.9)), color-stop(75%, rgba(255, 255, 255, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    /* Chrome10+,Safari5.1+ */
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    /* FF3.6+ */
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    /* IE10+ */
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    /* Opera 11.10+ */
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    /* W3C */
  }
  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter,
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_processing,
  .dataTables_wrapper .dataTables_paginate {
    color: #333333;
  }
  .dataTables_wrapper .dataTables_scroll {
    clear: both;
  }
  .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
    *margin-top: -1px;
    -webkit-overflow-scrolling: touch;
  }
  .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody th > div.dataTables_sizing,
  .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody td > div.dataTables_sizing {
    height: 0;
    overflow: hidden;
    margin: 0 !important;
    padding: 0 !important;
  }
  .dataTables_wrapper.no-footer .dataTables_scrollBody {
    border-bottom: 1px solid #111111;
  }
  .dataTables_wrapper.no-footer div.dataTables_scrollHead table,
  .dataTables_wrapper.no-footer div.dataTables_scrollBody table {
    border-bottom: none;
  }
  .dataTables_wrapper:after {
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0;
  }
  
  @media screen and (max-width: 767px) {
    .dataTables_wrapper .dataTables_info,
    .dataTables_wrapper .dataTables_paginate {
      float: none;
      text-align: center;
    }
    .dataTables_wrapper .dataTables_paginate {
      margin-top: 0.5em;
    }
  }
  @media screen and (max-width: 640px) {
    .dataTables_wrapper .dataTables_length,
    .dataTables_wrapper .dataTables_filter {
      float: none;
      text-align: center;
    }
    .dataTables_wrapper .dataTables_filter {
      margin-top: 0.5em;
    }
  }
  
  .leaflet-container {
    width: 100%;
    height: 200px;
  }