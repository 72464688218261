.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.nav.nav-pills .nav-link.active
{
    background-color: #337ab7;
    color: #ffffff;
}

.nav > li > a:hover {
  text-decoration:none;
  background-color:#eee;
}

.nav.nav-pills .nav-link
{
    color: #337ab7;
}

.nav-link.active {
  color: #eee;
}

.App-link {
  color: #09d3ac;
}

body {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 14px !important;
}

/* Everything but the jumbotron gets side spacing for mobile first views */
.header,
.marketing,
.footer {
  padding-right: 15px;
  padding-left: 15px;
}

/* Custom page header */
.header {
  padding-bottom: 20px;
  border-bottom: 1px solid #e5e5e5;
}
/* Make the masthead heading the same height as the navigation */
.header h3 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 40px;
  font-size: 24px;
}

/* Custom page footer */
.footer {
  padding-top: 19px;
  color: #777;
  border-top: 1px solid #e5e5e5;
}

.footer p {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 40px;
}

/* Customize container */
@media (min-width: 768px) {
  .container {
    max-width: 730px !important;
  }
}
.container-narrow > hr {
  margin: 30px 0;
}

/* Main marketing message and sign up button */
.jumbotron {
	padding-top : 10px;
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
  background: url("./resources/images/home-jumbo-background-700.png");
  background-size: 100% 100%;
}
.jumbotron .btn {
  padding: 14px 24px;
  font-size: 21px;
}

/* Supporting marketing content */
.marketing {
  margin: 40px 0;
}
.marketing p + h4 {
  margin-top: 28px;
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
  /* Remove the padding we set earlier */
  .header,
  .marketing,
  .footer {
    padding-right: 0;
    padding-left: 0;
  }
  /* Space out the masthead */
  .header {
    margin-bottom: 30px;
  }
  /* Remove the bottom border on the jumbotron for visual effect */
  .jumbotron {
    border-bottom: 0;
  }
}

.fixedTable {
	table-layout: fixed;
}

.linkCell {
	word-break : break-all;
}

.linkCell a {
	display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.wishCell {
	max-width: 0;
/* 	overflow : hidden; */
	font-size : 90%;
	vertical-align:top;
	border-top: 1px dotted;
	padding-top: 12px;
	padding-right: 10px;
	padding-bottom: 12px;
/* 	text-overflow: ellipsis;  */
}

.wishCell input {
	width: 100%;
}

.wishLastCell {
/* 	overflow : hidden; */
	max-width: 0;
	font-size : 90%;
	vertical-align:top;
	border-top: 1px dotted;
	padding-top: 12px;
	padding-bottom: 12px;
/* 	text-overflow: ellipsis; */
}
.editButtonsCell, .linkInput {
	border-top : 0px !important;
}

.pdfButton {
  left: 50%;
  right: auto;
  text-align: center;
  transform: translate(-50%, 0);
}

.pdfButtonContainer {
  left: 50%;
  right: auto;
  transform: translate(-50%, 0);
  z-index : 10000;
}

.pdfLink a {
	color: grey;
}

.pdfLink {
	margin-left : 17px;
	margin-bottom : 5px;
	font-size : 75%;
}

/* <!-- login styles --> */

@import url(https://fonts.googleapis.com/css?family=Roboto);

* {
    font-family: 'Roboto', sans-serif;
}

.popup-content .modal-dialog {
  width: 350px;
}

.popup-content input[type=text], input[type=password] {
  margin-top: 10px;
}

#div-login-msg,
#div-lost-msg,
#div-register-msg {
    border: 1px solid #dadfe1;
    height: 30px;
    line-height: 28px;
    transition: all ease-in-out 500ms;
}

#div-login-msg.success,
#div-lost-msg.success,
#div-register-msg.success {
    border: 1px solid #68c3a3;
    background-color: #c8f7c5;
}

#div-login-msg.error,
#div-lost-msg.error,
#div-register-msg.error {
    border: 1px solid #eb575b;
    background-color: #ffcad1;
}

#icon-login-msg,
#icon-lost-msg,
#icon-register-msg {
    width: 30px;
    float: left;
    line-height: 28px;
    text-align: center;
    background-color: #dadfe1;
    margin-right: 5px;
    transition: all ease-in-out 500ms;
}

#icon-login-msg.success,
#icon-lost-msg.success,
#icon-register-msg.success {
    background-color: #68c3a3 !important;
}

#icon-login-msg.error,
#icon-lost-msg.error,
#icon-register-msg.error {
    background-color: #eb575b !important;
}

#img_logo {
    max-height: 100px;
    max-width: 100px;
}

/* #########################################
   #    override the bootstrap configs     #
   ######################################### */

.modal-backdrop.in {
    filter: alpha(opacity=20);
    opacity: .2;
}

.popup-content .modal-content {
    background-color: #ececec;
    border: 1px solid #bdc3c7;
    border-radius: 0px;
    outline: 0;
}

.popup-content .modal-header {
    display: inline;
    min-height: 16.43px;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: 0px;
}

.popup-content .modal-header .close {
  padding: 0px;
  margin: 0px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.1) none repeat scroll 0% 0% !important;
} 

.popup-content {
  padding: 0px !important;
}

.modal-body {
    position: relative;
    padding: 5px 15px 5px 15px;
}

.modal-footer {
    padding: 15px 15px 15px 15px;
    text-align: left;
    border-top: 0px;
}

.checkbox {
    margin-bottom: 0px;
}

.btn {
    border-radius: 5px;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
    outline: none;
}

.btn-lg, .btn-group-lg>.btn {
    border-radius: 0px;
}

.btn-link {
    padding: 5px 10px 0px 0px;
    color: #95a5a6;
}

.btn-link:hover, .btn-link:focus {
    color: #2c3e50;
    text-decoration: none;
}

.form-control {
  border-radius: 0px;
}

.btn.btn-pill
{
  background-color: transparent;
  background-image: none;
  color: #337ab7;
  cursor: pointer;
  border-color: transparent;
  -webkit-box-shadow: none;
     -moz-box-shadow: none;
          box-shadow: none;
   padding: 7px 12px;
}

.btn.btn-pill:hover
{
  color: #005580;
  background-color: #EEE;
}

.btn.btn-pill:active,
.btn.btn-pill.active
{
    background-color: #005580;
    color: #fff;
}

.nav-pills .dropdown.show .nav-link {
  color : #000;
  padding: 0.25rem 1.5rem;
}
.nav-pills .dropdown > .nav-link.dropdown-toggle {
  padding: 0.5rem 1rem;
}

.nav-pills .dropdown.show > .nav-link.dropdown-toggle {
  padding: 0.5rem 1rem;
  background-color: #eee;
  color: #337ab7;
}

.nav-pills .dropdown.show {
  line-height: 1.5;
}

.no-padding-col {
	padding-left: 0;
	padding-right: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-padding-left-col {
	padding-left: 0;
}

.no-padding-right-col {
	padding-right: 0;
}

#mapdiv { height: 180px; }

.dataTables_wrapper {
	margin-top : 16px;
}

.dataTables_wrapper, .dataTable {
	font-size : 10px;
}
.material-icons.active {
	color : lightblue;
}

#runningTable td.type, #runningTable td.details-control {
    width : 24px;
}

#runningTable td i {
    width : 24px;
    vertical-align: middle;
}

#runningTable td.type i {
    width: 24px;
    font-size : 20px;
    vertical-align: middle;
}

.popup-content .modal {
  font-size: 12px;
  width: 350px !important;
}
.popup-content .modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.popup-content .modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.popup-content .modal-dialog {  
  padding: 0px;
  margin: auto !important;
}

.popup-content {
  width: auto !important;
}

a[title="Exit Full Screen"].leaflet-control-zoom-fullscreen.fullscreen-icon {
  background:url("./resources/images/fullscreen.png") 0px 26px ;
  background-color: white;
}

a.leaflet-control-zoom-fullscreen.fullscreen-icon {
  background:url("./resources/images/fullscreen.png") 0px 0px ;
  background-color: white;
}

/* blog styles */
.blogContainer {
  display: flex;
  flex-direction: column;
}



.BlogPost {
	padding-top : 32px;
	padding-bottom : 32px;
}

.BlogPost:NTH-CHILD(2) {
	padding-top : 0px;
}

.BlogPost .btn-group {
	margin-bottom : 10px;
}

.BlogPost:NOT(:LAST-CHILD) {
	border-bottom: 1px solid grey;
}

img[alt~="small"] {
   width:150px;
}

img[alt~="medium"] {
   width:300px;
}

img[alt~="large"] {
   width:600px;
}

img[alt~="full"] {
  width:100%;
}

img[alt~="small-left"] {
   	width:150px;
 	float:left;	
	margin-right : 20px;
}

img[alt~="medium-left"] {
   	width:300px;
	float:left;
	margin-right : 20px;
}

img[alt~="large-left"] {
   	width:600px;
	float:left;
	margin-right : 20px;
}

img[alt~="small-right"] {
   	width:150px;
 	float:right;	
	margin-left : 20px;
}

img[alt~="medium-right"] {
   	width:300px;
	float:right;
	margin-left : 20px;
}

img[alt~="large-right"] {
   	width:600px;
	float:right;
	margin-left : 20px;
}

.headerdate {
	font-size: 10px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}



.modal.show {
  display: block;
}
